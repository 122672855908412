import React from 'react';
import { Carousel } from 'antd';
import swal from 'sweetalert';

const carouselStyle = {
  width: '100%', // Set the overall width of the Carousel to 100% to allow responsiveness
  margin: '0 auto', // Center the Carousel horizontally
  display: 'flex', // Make Carousel items display horizontally
};

const contentStyle = {
  height: '300px',
  width: '100%',
  color: '#fff',
  lineHeight: '300px',
  textAlign: 'center',
  background: '#364d79',
  borderRadius: '15px',
};

const showImage = (imgUrl) => {
  swal({
    buttons: {
      cancel: 'Close'
    },
    content: {
      element: "img",
      attributes: {
        src: `data:image;base64,${imgUrl}`,
        style: "width: 100%; max-width: 300px; height: auto;"
      }
    },
  });

};

const CarouselCommon = ({ profilePhotos }) => {
  return (
    <Carousel autoplay style={carouselStyle}>
      {profilePhotos && profilePhotos.length > 0 && (
        <>
          {profilePhotos.map((photo) => (
            <div key={photo.id} style={{ display: 'inline-block' }}> {/* Update: Add display: 'inline-block' to display images horizontally */}
              <img className="othermini" src={`data:image;base64,${photo.fileContents}`} alt={`otherproimg${photo.id}`} key={photo.id} onClick={() => showImage(photo.fileContents)} />
            </div>
          ))}
        </>
      )}
    </Carousel>
  );

};

export default CarouselCommon;
