import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MicrosoftLoginAgent } from "./Services/Authentication/msal";
import { MemberProvider } from "./Contexts/memberContext";
import { MsalProvider } from "@azure/msal-react";
import { AppProvider } from './Contexts/appContext';
import LandingPage from './Components/Login/LandingPage.js';
import Dashboard from './Components/Dashboard/Dashboard.js';
import Layout from './Components/NavBar/Layout';
import MemberDashBoard from './Components/Login/MemberDashboard.js';
import RegistrationForm from './Components/Registration/Registration';
import ProfileCreation from './Components/ProfileCreation/ProfileCreation';
import SingleSignOn from './Components/Login/SingleSignOn.js';
import VerifyEmail from './Components/Registration/RegistrationSuccess.js';
import VerifyNavigate from './Components/Registration/VerifyEmail.js';
import Footer from './Components/FooterPages/Footer.js';
import Header from './Components/NavBar/Header.js';
// import MatchingCriteria from './Components/Login/MatchingCriteria';
import '../src/CSS/style.css';
import ResultPage from './Components/ResultPage/ResultPage';
import FilterBar from './Components/ResultPage/FilterBar';
import OtherProfile from './Components/Profiles/OtherProfile.js';
import MyProfile from './Components/Profiles/MyProfile.js';
import Search from './Components/Search/Search.js';
import Contact from './Components/FooterPages/ContactArea.js';
import FAQ from './Components/FooterPages/FAQs.js';
import Terms from "./Components/FooterPages/terms.js";
import Policy from "./Components/FooterPages/policies.js";
import PrivateRoute from './Components/Common/PrivateRoute.js';

function App() {
  return (
    <Router>
      <MsalProvider instance={MicrosoftLoginAgent.msalInstance}>
        <AppProvider>
          <MemberProvider>
            <Routes>
              <Route path="/contactus" element={<Contact />} />
              <Route element={<Footer />} />
              <Route element={<Header />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/policy" element={<Policy />} />
              <Route element={<PrivateRoute currentPath={window.location.href} />}>
                <Route path="/registration" element={<RegistrationForm />} />
                <Route path="/profilecreation" element={<ProfileCreation />} />
                <Route path="/verifyemail" element={<VerifyEmail />} />
                <Route path="/filterbar" element={<FilterBar />} />
                <Route element={<Layout />}>
                  <Route path="/board" element={<Dashboard />} />
                  <Route path="/resultpage" element={<ResultPage />} />
                  <Route path="/otherprofiles" element={<OtherProfile />} />
                  <Route path="/myprofile" element={<MyProfile />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/otherprofile" element={<OtherProfile />} />
                </Route>
              </Route>
              <Route path="/" element={<LandingPage />} />
              <Route path="/sso" element={<SingleSignOn />} />
              <Route path="/dashboard" element={<MemberDashBoard />} />
              <Route path="/registration/confirm-mail" element={<VerifyNavigate />} />
              {/* <Route path="/matchingCriteria" element={<MatchingCriteria />} /> */}
            </Routes>
          </MemberProvider>
        </AppProvider>
      </MsalProvider>
    </Router>
  );
}

export default App;
