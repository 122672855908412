import React, { useState } from 'react';
import { Carousel } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteProfilePhoto, removeHoroscope, updateMainPhoto } from '../../Services/Profile/photoService';
import swal from 'sweetalert';
import { useMember } from '../../Contexts/memberContext';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { FaLeaf } from 'react-icons/fa';

const carouselStyle = {
  width: '100%', // Set the overall width of the Carousel to 100% to allow responsiveness
  margin: '0 auto', // Center the Carousel horizontally
  display: 'flex', // Make Carousel items display horizontally
};

const contentStyle = {
  height: '300px',
  width: '100%',
  color: '#fff',
  lineHeight: '300px',
  textAlign: 'center',
  background: '#364d79',
  borderRadius: '15px',
};

const HoroscopeCarousel = ({ horoscopePhotos,
  profileId,
  handlePhotosLoading,
  handleImgRemove,
  handleMainProfile }) => {
  const { profile, setProfile } = useMember();
  const [picOne, setPicOne] = useState(false);
  const [picTwo, setPicTwo] = useState(false);
  const [picThree, setPicThree] = useState(false);
  const handleHoroscopePhoto = async (id, imgSrc) => {
    swal({
      text: "Do you want to remove this photo?",
      buttons: {
        cancel: 'Close',
        delete: {
          text: 'Remove photo',
          className: 'request-button',
          value: "delete"
        }
      },
      content: {
        element: "img",
        attributes: {
          src: `data:image;base64,${imgSrc}`,
          style: "width: 100%; max-width: 300px; height: auto;"
        }
      }
    }).then(async (value) => {
      if (value === "delete") {
        const removeProfilePhoto = await removeHoroscope(profileId, id);
        if (removeProfilePhoto.status === 204) {
          //handlePhotosLoading();
          swal("Successfully Deleted");
          handleImgRemove(id);
          return;
        }
      }
    });

  };

  const handleToggle = (index) => {
    if (index === 0) {
      setPicOne(!picOne);
      setPicTwo(false);
      setPicThree(false);
    } else if (index === 1) {
      setPicTwo(!picTwo);
      setPicOne(false);
      setPicThree(false);
    } else if (index === 2) {
      setPicThree(!picThree);
      setPicOne(false);
      setPicTwo(false);
    }
  };


  return (
    <Carousel autoplay style={carouselStyle}>
      {horoscopePhotos && horoscopePhotos.length > 0 && (
        <>

          {horoscopePhotos.map((photo, index) => (
            <div key={photo.id} style={{ display: 'inline-block' }}> {/* Update: Add display: 'inline-block' to display images horizontally */}
              <img className="othermini" style={{ filter: (photo.isApproved) ? 'none' : 'blur(4px)' }} src={`data:image;base64,${photo.fileContents}`} alt={`otherproimg${photo.id}`} key={photo.id} onClick={() => handleHoroscopePhoto(photo.id, photo.fileContents)} />
              {!photo.isApproved && (
                <div>
                  {index === 0 && (
                    <Tooltip
                      title="Waiting for moderator approval"
                      open={picOne}
                      arrow
                      onClick={() => handleToggle(index)}
                      placement="top"
                    >
                      <IconButton onClick={() => handleToggle(index)}>
                        <InfoIcon style={{ width: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {index === 1 && (
                    <Tooltip
                      title="Waiting for moderator approval"
                      open={picTwo}
                      arrow
                      onClick={() => handleToggle(index)}
                      placement="top"
                    >
                      <IconButton onClick={() => handleToggle(index)}>
                        <InfoIcon style={{ width: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {index === 2 && (
                    <Tooltip
                      title="Waiting for moderator approval"
                      open={picThree}
                      arrow
                      onClick={() => handleToggle(index)}
                      placement="top"
                    >
                      <IconButton onClick={() => handleToggle(index)}>
                        <InfoIcon style={{ width: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </Carousel>
  );

};

export default HoroscopeCarousel;
